import request from '@/utils/request';

/** 分页查询 */
export function listPageOrderInfo(data) {
    return request({
        url: '/movecar/orderInfo/listPageOrderInfo',//请求路径
        method: 'get',
        params:data
    });
}
/** 导出 */
export function exportOrderInfo(data) {
    return request({
        url: '/movecar/orderInfo/exportOrderInfo',//请求路径
        method: 'get',
        params:data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}