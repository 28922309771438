import request from '@/utils/request';
import Qs from 'qs'

/**
 *          库存管理
 */
/** 库存列表 */
export function deviceList(data) {
    return request({
        url: '/base/device/listPageDevice',//请求路径
        method: 'get',
        params: data
    });
}

/** 批量导入 */
export function batchImportDevice(data) {
    return request({
        url: '/base/device/importDevice',//请求路径
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

/** 库存模板下载 */
export function deviceDownloadTemplate(data) {
    return request({
        url: '/base/device/downloadTemplate',//请求路径
        method: 'get',
        params: data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}

/** 库存管理修改 */
export function editDevicePort(data) {
    return request({
        url: '/base/device/modifyDevice',//请求路径
        method: 'post',
        data,
    });
}

/** 库存管理删除 */
export function deleteDevice(data) {
    return request({
        url: '/base/device/deleteDevice',//请求路径
        method: 'post',
        data,
    });
}

/** 设备类型列表 */
export function listDeviceModelByOrgId(data) {
    return request({
        url: '/base/deviceModel/listDeviceModelByOrgId',//请求路径
        method: 'get',
        params: data,
    });
}