<template>
  <div>
    <div class="screen">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery"  @submit.native.prevent>
                    <div class="form jcl">
                        <div class="search-conditions">   
                            <el-form-item label="设备编号/IMEI" prop="imei">
                                <el-input class="input-w290-h32" v-model="listQuery.imei" placeholder="请输入设备编号/IMEI"></el-input>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="includeSub">
                                <el-checkbox v-model="containsLower">包含下级用户统计</el-checkbox>
                            </el-form-item>
                            <tem-btn type="primary"  value="选择发送指令" @click="batchSend"></tem-btn>
                            <tem-btn type="primary" value="查询" @click="getTableList('search')"></tem-btn>
                            <tem-btn type="info" value="清空" @click="restForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="table-list">
        <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
            :orderBy.sync="listQuery.orderBy" @pagination="getTableList" ref="multipleTable" @changeSelect="changeSelect" ></Table>
    </div>
    <el-dialog title="发送指令" :visible.sync="sendVisible" :close-on-click-modal="false" width="500px">
      <div class="select">
        <span class="tip-font">指令类型 </span>
        <el-select v-model="instructionType" class="input-w290-h32" size="small" placeholder="请选择" @change="instructionTypeSelect">
            <el-option v-for="item in instructionOptions" :key="item.id" :label="item.instructName" :value="item.id">
            </el-option>
        </el-select>
      </div>
      <div>
        <el-form label-width="120px">
          <el-form-item :label="item.paramName" v-for="(item,index) in formList" :key="index">
            <el-input v-if="item.paramType == 'input'" class="input-w290-h32" v-model="item.defaultValue" size="small">
            </el-input>
            <el-input v-else-if="item.paramType == 'text'" class="input-w290-h32" v-model="item.defaultValue"
              size="small"></el-input>
            <el-input v-else-if="item.paramType == 'tel'" class="input-w290-h32" v-model="item.defaultValue" size="small">
            </el-input>
            <el-input v-else-if="item.paramType == 'nub'" class="input-w290-h32" v-model="item.defaultValue" size="small">
            </el-input>
            <el-input v-else-if="item.paramType == 'email'" class="input-w290-h32" v-model="item.defaultValue"
              size="small"></el-input>
            <el-input v-else-if="item.paramType == 'password'" class="input-w290-h32" v-model="item.defaultValue"
              size="small"></el-input>
            <el-select v-else-if="item.paramType == 'select'" class="input-w290-h32" size="small" v-model="item.defaultValue">
              <el-option v-for="(key,index) in JSON.parse(item.paramValue)" :key="index" :label="key.name"
                :value="key.val"></el-option>
            </el-select>
            <el-radio-group v-else-if="item.paramType == 'radio'" class="input-w290-h32" size="small" v-model="item.defaultValue">
              <el-radio v-for="(key,index) in JSON.parse(item.paramValue)" :key="index" :label="key.name"
                :value="key.val"></el-radio>
            </el-radio-group>
            <el-checkbox-group v-else-if="item.paramType == 'checkbox'" class="input-w290-h32" size="small" v-model="item.defaultValue">
              <el-checkbox v-for="(key,index) in JSON.parse(item.paramValue)" :key="index" :label="key.name"
                :value="key.val" name="type"></el-checkbox>
            </el-checkbox-group>
            <el-input v-else-if="item.paramType == 'textarea'" type="textarea" size="small" v-model="item.paramValue">
            </el-input>
            <el-input v-else v-model="item.paramValue" class="input-w290-h32" size="small"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="selec" v-show="moreTag">
        <el-tag class="tag" v-for="tag in imeis" :key="tag" closable type="info" @close="handleCloseTag(tag)">{{tag}}
        </el-tag>
      </div>
      <span slot="footer" class="dialog-footer" style="margin-top:20px">
        <tem-btn  type="primary" :loading="loading" @click="sendSubmit"  value="确定"></tem-btn>
      </span>
    </el-dialog>

    <el-dialog title="指令记录" :visible.sync="orderRecordVisible" :close-on-click-modal="false" width="1000px">
      <tem-btn  type="primary" @click="cancellingCommand"  value="选择取消指令" style="margin-bottom:15px;"></tem-btn>
      <Table :table="orderRecordTable" :total="orderRecordTotal" @changeSelect="changeHistorySelect"
        :page.sync="historyPage" :size.sync="historySize" :orderBy.sync="hisOrderBy" @pagination="getRecord"
        class="table">
      </Table>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
    import { getOrgId,getOrgCode } from '@/utils/cookie'
    import {
        sendInstructAsync,
        selectInstructByDeviceType,
        listPageDevice,
        instructRecord,
        instructDevice,
        DeviceTypeInstruct,
        sendInstruct,
        orderRecord,
        cancelInstructRecord
    } from '@/api/orderManagement';
    import {
        deviceTypeList,
        deviceMatching
    } from '@/api/deviceManagement';
    import Table from '../../components/table/table';
    Array.prototype.indexOf = function (val) {
        for (var i = 0; i < this.length; i++) {
        if (this[i] == val) return i;
        }
        return -1;
    };
    Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
        this.splice(index, 1);
        }
    };
  export default {
    components: {
      Table
    },
    data() {
      return {
          deviceFlag:true,//用于判断所选的设备是不是一个类型
        //搜索栏数据
        listQuery:{
            imei:null,
            total:10000,//数据条数
            //includeSub:false,//是否包含下级
            pageIndex: 1,//当前查询页面
            pageSize: 10,//每页数据数量
            //orderBy:''//排序
        },
        imeiHistory: '',
        loading: false,
        recordIds: [],
        moreTag: false,
        formList: [],
        sendVisible: false,
        orderRecordVisible: false,
        containsLower: false,
        restaurants: [], // 模糊匹配数组
        deviceTypeOptions: [{
          value: 2
        }], // 设备型号下拉框
        instructionOptions: [],
        sendOptions: [{
          value: '1',
          label: '离线'
        }, {
          value: '0',
          label: '在线'
        }],
        synchronizationOptions: [{
          value: true,
          label: '同步'
        }, {
          value: false,
          label: '异步'
        }],
        value: false,
        table: {
          configItem: true, //是否开启表格项配置
          data: [],
          header: [{
              type: 'selection',
              width: '55',
              ifShow: true,
            },
            {
              type: 'index',
              label: '序号',
              width: '55',
              ifShow: true,
            },
            {
              prop: 'imei',
              label: 'imei',
              minWidth: '180',
              ifShow: true,
            },
            {
              prop: 'deviceName',
              label: '设备名称',
              minWidth: '180',
              ifShow: true,
              // width:'150',
            },
            {
              prop: 'deviceModel',
              label: '设备类型',
              minWidth: '180',
              ifShow: true,
            },
            {
              prop: 'createTime',
              label: '创建时间',
              sortable: 'column',
              minWidth: '180',
              ifShow: true,
            //   render(h, param) {
            //     var d = new Date(param.recordCreateTime).toJSON();
            //     var date = new Date(+new Date(d) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(
            //       /\.[\d]{3}Z/, '')
            //     return h('span', date)
            //   },
            },
            {
              prop: 'updateTime',
              label: '更新时间',
              minWidth: '180',
              sortable: 'column',
              ifShow: true,
            //   render(h, param) {
            //     var d = new Date(param.recordUpdateTime).toJSON();
            //     var date = new Date(+new Date(d) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(
            //       /\.[\d]{3}Z/, '')
            //     return h('span', date)
            //   },
            },
            {
              prop: 'options',
              label: '操作',
              minWidth: '240',
              ifShow: true,
              fixed: 'right',
              render: (h, param) => {
                return h('div', [
                    h('span', {
                        props: {
                            size: 'small'
                        },
                        style:{
                            cursor:"pointer", 
                            color:"#3270FF",
                            marginRight:"10px"
                        },
                        on: {
                            click: () => {
                                this.sendOrder(param);
                            }
                        }
                    }, '发送指令'),
                    h('span', {
                        props: {
                            size: 'small'
                        },
                        style:{
                            cursor:"pointer", 
                            color:"#3270FF"
                        },
                        on: {
                            click: () => {
                                this.getOrderRecord(param);
                            }
                        }
                    }, '指令发送历史'),
                ]);
              }
            }
          ],
        },
        orderRecordTable: {
          data: [],
          header: [{
                type: 'selection',
                width: '55',
                selectable: (param, index) => {
                    if (param.sendState == 'SEND_WAIT') {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            {
                type: 'index',
                label: '序号',
                width: '55'
            },
            {
                prop: 'imei',
                label: 'imei',
                width: '180'
            },
            {
              prop: 'sendUser',
              label: '发送人',
              tooltip: true
            },
            {
              prop: 'instructContent',
              label: '指令内容',
              tooltip: true
            },
            {
              prop: 'sendTime',
              label: '发送时间',
              sortable: 'column',
              width: '180'
            },
            {
              prop: 'cancelUser',
              label: '取消人',
            },
            {
              prop: 'cmdReply',
              label: '指令回复',
              width: '100',
              tooltip: true
            },
            {
              prop: 'sendState',
              label: '发送状态',
              render(h, param) {
                if (param.sendState == 'SEND_FAIL') {
                  return h('span', {
                      style: {
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: '#F56C6C'
                      },
                    },
                    '发送失败')
                } else if (param.sendState == 'SEND_SUCCESS') {
                  return h('span', {
                      style: {
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: '#67C23A'
                      },
                    },
                    '发送成功')
                } else if (param.sendState == 'SEND_WAIT') {
                  return h('span', {
                      style: {
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: '#E6A23C'
                      },
                    },
                    '待发送')
                } else if (param.sendState == 'SEND_CANCEL') {
                  return h('span', {
                      style: {
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: '#909399'
                      },
                    },
                    '已取消')
                }
              },
            },
            {
              prop: 'sendType',
              label: '发送类型',
              render(h, param) {
                if (param.sendType == 'ONLINE') {
                  return h('span', {
                      style: {
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: '#67C23A'
                      },
                    },
                    '在线')
                } else if (param.sendType == 'OFFLINE') {
                  return h('span', {
                      style: {
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: '#909399'
                      },
                    },
                    '离线')
                }
              },
            },
            {
              prop: 'options',
              label: '操作',
              width: '120',
              fixed: 'right',
              render: (h, param) => {
                if (param.sendState == 'SEND_WAIT') {
                  return h('div', [
                    h('span', {
                      props: {
                        size: 'small'
                      },
                      style:{
                            cursor:"pointer", 
                            color:"#3270FF"
                        },
                      on: {
                        click: () => {
                          this.cancelInstruct(param.id);
                        }
                      }
                    }, '取消指令')
                  ]);
                } else {
                  return h('div', [
                    h('span', {
                      props: {
                        size: 'small'
                      },
                      on: {
                        click: () => {
                          // this.cancelInstruct(param);
                        }
                      }
                    }, '不能操作')
                  ]);
                }
              }
            }
          ]
        },
        historySize: 10,
        historyPage: 1,
        hisOrderBy: '', //指令记录
        orderRecordTotal: 10,
        instructionType: '',
        sendType: '',
        synchronization: '',
        cmdId: '',
        taskId: '',
        deviceType: '',
        cmdContent: '',
        imei: '',
        imeis: []
      }
    },
    created() {
      // if (this.$store.state.user.imei) {
      //     this.imei = this.$store.state.user.imei;
      // }
      //获取列表数据
        this.getTableList();
    },
    mounted() {
      if (this.$route.params.imei) {
        this.imei = this.$route.params.imei + '';
      }
    },
    methods: {
        /**
         * 重置搜索导航栏
         * @param { Object } formName 当前表单的ref值
         */
        restForm(formName){
            this.$refs[formName].resetFields();
        },
        /**
         * 获取表格数据
         */
        getTableList(val){
            if(val == "search"){
                this.listQuery.pageIndex =1 ;
            }
            listPageDevice(this.listQuery).then( res => {
                if( res.code === 200 ) {
                    this.table.data = res.data;
                    this.listQuery.total = res.count;
                }
            })
        },
        /**取消指令发送 */
        cancelInstruct(param) {
            cancelInstructRecord({"ids":param}).then(res => {
                if (res.code == 200) {
                    // param.sendState = 'SEND_CANCEL'
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    });
                }
            })
        },
        /** 模糊查询imei */
        querySearch(queryString, cb) {
            if (queryString.trim() == '') {
            cb([])
            return;
            }
            deviceMatching({
            imei: queryString
            }).then(res => {
            if (!res.data.code) {
                let arr = [];
                for (var i = 0; i < res.data.data.length; i++) { // 处理成模糊查询的数据
                let obj = {};
                obj.value = res.data.data[i];
                arr.push(obj);
                }
                cb(arr);
            } else {
                this.$message.error(res.data.msg);
            }
            })
        },
        /**删除Tag */
        handleCloseTag(tag) {
            this.imeis.remove(tag);
            this.$refs.multipleTable.toggleRowSelection(this.imeis);
            console.log('tag', tag)
        },
        /**批量发送 */
        batchSend() {
            var lenght = this.imeis.length;
            if (lenght > 0) {
                if(this.deviceFlag){
                    this.deviceDialogVisible = true;
                    this.sendVisible = true;
                    this.getDeviceTypeInstruct();
                    this.moreTag = true;
                    this.imei = '';
                    var cmdContent = '';
                    // console.log('object', this.formList);
                    //sendInstructAsync
                    this.formList.forEach((element, index) => {
                        // console.log(element, index, element.defaultValue);
                        cmdContent = this.cmdContent.replace("{" + index + "}", element.defaultValue);
                        // console.log('object', this.cmdContent)
                    });
                    this.loading = true;
                    let params = {
                        fullUserId:getOrgCode(),
                        imeiList:this.imeis,
                        // imeiList:['111111111111111'],
                        instructContent:cmdContent || this.cmdContent,
                        instructId:this.cmdId,
                        platform:'web',
                        sendUser:getOrgId(),
                        time:10
                    }
                    sendInstructAsync(params).then(res => {
                    // sendInstruct(params).then(res => {
                        this.loading = false;
                        // if (res.data.code == 0) {
                            // this.instructionOptions = res.data.data;
                            this.sendVisible = false;
                            this.$message({
                                message: "指令发送成功",
                                type: 'success'
                            });
                        // } else {
                        //     this.$message({
                        //     message: res.data.msg,
                        //     type: 'error'
                        //     });
                        // }
                    })
                }else{
                    this.$message({
                        message: '请选择相同设备类型',
                        type: 'error'
                    });
                }
            } else {
                this.$message({
                    message: '请选择指令',
                    type: 'error'
                });
            } 
        },
        /**批量取消 */
        cancellingCommand() {
            var _this = this;
            var lenght = this.recordIds.length;
            
            if (lenght) {
                // console.log('_this.recordIds.toString()', _this.recordIds.toString())
                this.cancelInstruct(_this.recordIds.toString());
            } else {
                this.$message({
                    message: '请选择',
                    type: 'error'
                });
            }
        },
        /**勾选选择触发事件 */
        changeSelect(val) {
            this.imeis = [];
            this.deviceFlag = true;
            if (val.length > 0) {
                this.deviceType = val[0].deviceModel;
                val.forEach(element => {
                    this.imeis.push(element.imei);
                    if (this.deviceType != element.deviceModel) {
                        this.deviceFlag = false;
                    }
                });
            }
        },
        /**指令发送历史-勾选选择触发事件 */
        changeHistorySelect(val) {
            this.recordIds = [],
            val.forEach(element => {
                this.recordIds.push(element.id)
            });
            console.log('this.recordIds', this.recordIds.toString())
        },
        /**指令记录 */
        getOrderRecord(val) {
            this.imeiHistory = val.imei;
            this.orderRecordVisible = true;
            this.getRecord();
        },
        /**刷新指令记录 */
        getRecord() {
            var params = {
                imei: this.imeiHistory,
                // imei: 111111111111111,
                limit: this.historySize,
                page: this.historyPage,
                taskId: '',
                orderBy: this.hisOrderBy || ''
            }
            instructRecord(params).then(res => {
            // orderRecord(params).then(res => {
                // if (res.data.code == 0) {
                    this.orderRecordTable.data = res.data;
                    this.orderRecordTotal = res.count;
                // } else {
                //     this.$message({
                //         message: res.data.msg,
                //         type: 'error'
                //     });
                // }
            })
        },
        /**点击发送 */
        sendSubmit() {
            var cmdContent = '';
            console.log('object', this.formList);
            this.formList.forEach((element, index) => {
                // console.log(element, index, element.defaultValue);
                cmdContent = this.cmdContent.replace("{" + index + "}", element.defaultValue);
                // console.log('object', this.cmdContent)
            });
            this.loading = true;
            // var params = {
            //     cmdContent: cmdContent || this.cmdContent,
            //     cmdId: this.cmdId,
            //     imeis: this.imeiHistory || this.imeis.toString(),
            // }
            var params = {
                fullUserId:getOrgCode(),
                imeiList:this.imeiHistory || this.imeis.toString(),
                // imeiList:['111111111111111'],
                instructContent:cmdContent || this.cmdContent,
                instructId:this.cmdId,
                platform:'web',
                sendUser:getOrgId(),
                time:10
            }
            sendInstructAsync(params).then(res => {
            // sendInstruct(params).then(res => {
                this.loading = false;
                if (res.data.code == 0) {
                    // this.instructionOptions = res.data.data;
                    this.sendVisible = false;
                    this.$message({
                        message: "指令发送成功",
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        /**改变指令类型 */
        instructionTypeSelect(val) {
            if(this.instructionOptions.length == 0)return
            // console.log(this.instructionOptions)
            this.cmdId = val;
            var index = this.instructionOptions.map(item => item.id).indexOf(val);
            this.formList = this.instructionOptions[index].instructParamVOList;
            this.cmdContent = this.instructionOptions[index].instructContent;
            console.log('object', this.cmdContent)
            for (var i = 0; i < this.formList.length; i++) {
                if (this.formList[i].paramType == "checkbox" && typeof (this.formList[i].defaultValue) == 'string') {
                    this.formList[i].defaultValue = JSON.parse(this.formList[i].defaultValue)
                }
            }
        },
        /**发送指令 */
        sendOrder(val) {
            this.deviceType = val.deviceModel;
            this.imeiHistory = val.imei;
            this.sendVisible = true;
            this.getDeviceTypeInstruct();
            this.moreTag = false;
        },
        getDeviceTypeInstruct() {
            // selectInstructByDeviceType({deviceType:'<string>'}).then(res => {
            selectInstructByDeviceType(this.deviceType).then(res => {
            // DeviceTypeInstruct(this.deviceType).then(res => {
            // if (res.data.code == 0) {
                this.instructionOptions = res.data;
            // } else {
            //     this.$message({
            //     message: res.data.msg,
            //     type: 'error'
            //     });
            // }
            })
        }
    },
    watch: {
      containsLower: function () {
        this.getTableList();
      }
    },
    destroyed() {
      this.$store.dispatch('user/sevaImei', '');
    }
  }

</script>
<style lang="scss" scoped>
  .batch {
    margin: 20px 0;
  }

  .screen {
    // margin: 20px 0;
  }

  .input-wid {
    width: 180px;
  }

  .subordinate {
    margin: 0 10px;
  }

  .select {
    margin-bottom: 20px;
  }

  .tip-font {
    display: inline-block;
    width: 105px;
    text-align: right;
    margin-right: 12px;
  }

  .cancelled {
    margin-bottom: 10px;
  }

  .selec {
    width: 100%;
    height: 200px;
    overflow-y: auto;
    margin-top: 10px;
    border: 2px solid #f3f3f3;
    border-radius: 10px;
  }

  .tag {
    margin: 10px;
  }

</style>

<style>

</style>
